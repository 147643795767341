import React from "react";
import { contact, section5Title, social } from "../../profile";

const Contact = () => {
  return (
    <div className="parallax">
      <div data-aos="zoom-in-up" data-aos-once="true" className="git-form">
        <>
          <div className="git-head-div text-center mx-auto">
            <h1 id="Contact" className="git-head">
              {section5Title}
            </h1>
          </div>
        </>
        <div
          className="container "
          style={{ paddingBottom: "40px", paddingTop: "24px" }}
        >
          <div className="git-cont  ">
            <div className="row ">
              <div className="col-12 col-sm-6  ">
                {/* <form
                action={
                  contact.contactUrl
                    ? contact.contactUrl
                    : "https://formspree.io"
                }
                method={contact.contactUrl ? "POST" : "GET"}
              >
                <input
                  type="text"
                  id="fname"
                  name="firstname"
                  placeholder="Your name"
                  required
                ></input>
                <input
                  type="mail"
                  id="mailid"
                  name="Email"
                  placeholder="Email Address"
                  required
                ></input>
                <input
                  type="text"
                  id="sub"
                  name="Subject"
                  placeholder="Subject"
                  required
                ></input>
                <textarea
                  id="msg"
                  name="message"
                  placeholder="Message"
                  required
                ></textarea>
                <button style={{ cursor: "pointer" }} type="submit">
                  Send Message
                </button>
              </form> */}
                <div className="card contact-card ">
                  <ul class="list-group contact-info">
                    <li className="list-group-item ">
                      <div class="info-title">
                        <span class="fa fa-phone"></span> Phone
                      </div>
                      <div class="info-detail">
                        <a href="tel: 9861219456">9861219456</a>
                      </div>
                    </li>
                    <li className="list-group-item ">
                      <div class="info-title">
                        <span class="fa fa-envelope"></span> email
                      </div>
                      <div class="info-detail">
                        <a href="pratibhapaudel555@gmail.com">
                          pratibhapaudel555@gmail.com
                        </a>
                        <span></span>
                      </div>
                    </li>
                    <li className="list-group-item ">
                      <div class="info-title">
                        <span class="fa fa-map-marker"></span> Address
                      </div>
                      <div class="info-detail">
                        <address>
                          <a
                            href="https://goo.gl/maps/sa9ZrXkQVgSUyZRw7"
                            target="__blank"
                          >
                            Bode Planning, Madhyapur Thimi, Bhaktapur, Nepal
                          </a>
                        </address>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 half ">
                <p className="lead">{contact.pitch}</p>
                <div className="d-flex justify-content-center align-items-center flex-column ">
                  <div className="inline-block">
                    {social.linkedin && (
                      <a
                        title="Visit Linkedin profile"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={social.linkedin}
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    )}
                    {social.facebook && (
                      <a
                        title="Visit Facebok profile"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={social.facebook}
                      >
                        <i className="fab fa-facebook"></i>
                      </a>
                    )}
                    {social.twitter && (
                      <a
                        title="Visit Twitter profile"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={social.twitter}
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    )}
                    {social.instagram && (
                      <a
                        title="Visit Instagram profile"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={social.instagram}
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    )}
                    {social.github && (
                      <a
                        title="Visit Github profile"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={social.github}
                      >
                        <i className="fab fa-github"></i>
                      </a>
                    )}
                    <br />
                  </div>
                  {social.resume && (
                    <a href={social.resume} target="__blank">
                      <button
                        type="button"
                        class="btn btn-warning margin-top-1 "
                      >
                        Download Resume
                      </button>
                    </a>
                  )}
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
      <p id="not-dark" className="Copy">
        2020 © Copyright <strong>{contact.copyright}</strong>. All Rights
        Reserved
      </p>
    </div>
  );
};

export default Contact;
